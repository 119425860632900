/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function setActiveHeaderImage( index ) {

}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('main').fitVids();

        $(document).ready(function() {

          // var imageHeight = $('main').width() * 0.44;
          var containerHeight = $('#page-header-images').height();

          var numImages = $('#page-header-images img').size();
          var captionHeight = containerHeight / numImages;

          // $("#page-header-images").css('height', imageHeight+'px');
          // $(".header-caption-wrap").css('height', imageHeight+'px');
          $('.header-caption').css('height', captionHeight+'px');
          $('.header-caption:first').addClass('active');
          $('#page-header-images img:first').addClass('active');

          $('.header-caption').bind('click', function() {
            var index = $(this).index();
            $('#page-header-images img').removeClass('active');
            $('#page-header-images img:eq('+index+')').addClass('active');
            $('.header-caption').removeClass('active');
            $('.header-caption:eq('+index+')').addClass('active');

          });
        });

        if ( $('#page-header-images').length > 0 ) {
            var activeSlideNum = 0;
            // count number of items in slider
            var numSlides = $('#page-header-images .header-caption').length;

            var activeSlideInterval = setInterval( function() {
                var nextSlideNum = ( activeSlideNum + 1 < numSlides ) ? (activeSlideNum + 1) : 0;

                $('#page-header-images .header-image-wrap img, #page-header-images .header-caption').removeClass('active');
                $('#page-header-images .header-image-wrap img:eq('+nextSlideNum+'), #page-header-images .header-caption:eq('+nextSlideNum+')').addClass('active');
                activeSlideNum = nextSlideNum;
            },6000);

        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


//add class to body

  $(document).ready(function(){
    // $('body').css({'background' : ''});

    if ( $('li.page-item-1990').hasClass('current_page_item') || $('body').hasClass('parent-pageid-1990') || $('li.page-item-2218').hasClass('current_page_item') || $('body').hasClass('parent-pageid-2218') || $('body').hasClass('post-template-template-tree-media-post')) {

      $('body').addClass('tree-project');
      // $('body').css('background-color', 'transparent');
    }
  });


})(jQuery); // Fully reference jQuery after this point.
